import { en, pl } from '@formkit/i18n'
import { generateClasses } from '@formkit/themes'
import { genesisIcons } from '@formkit/icons'
import type { DefaultConfigOptions } from '@formkit/vue'
import formkitTailwindTheme from '~/tailwind/formkit-tailwind-theme'

const config: DefaultConfigOptions = {
    icons: { ...genesisIcons },
    locales: { en, pl },
    locale: 'pl',
    config: {
        classes: generateClasses(formkitTailwindTheme),
    },
}

export default config
