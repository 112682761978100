<template>
    <div class="fixed flex flex-col items-center top-16 right-2 w-80 z-50">
        <template v-for="notification in notifications" :key="`${notification.id}-${notification.type}`">
            <component
                :is="notificationsComponent(notification.type)"
                :id="notification.id"
                :message="notification.message"
                @notification-close="onNotificationClose"
            />
        </template>
    </div>
</template>

<script setup lang="ts">
    export interface Notification {
        id: number
        type: string
        message: string
    }

    const { $bus } = useNuxtApp()
    const notifications = ref<Notification[]>([])
    const id = ref(0)

    function notificationsComponent(param: string) {
        switch (param) {
            case 'success':
                return resolveComponent('LazyNotificationSuccess')
            case 'error':
                return resolveComponent('LazyNotificationError')
        }
    }

    function onNotificationClose(index: number) {
        notifications.value = notifications.value.filter((notification) => {
            return notification.id !== index
        })
    }

    $bus.$on('notification', (notification) => {
        notifications.value = [...notifications.value, { ...(notification as Notification), id: id.value++ }]
    })
</script>
