import { default as addPkdR7cOMRoMeta } from "/codebuild/output/src1965977027/src/transo/pages/app/cars/add.vue?macro=true";
import { default as _91id_93OXXofMzT1pMeta } from "/codebuild/output/src1965977027/src/transo/pages/app/cars/edit/[id].vue?macro=true";
import { default as indexyfHSrOJ3vJMeta } from "/codebuild/output/src1965977027/src/transo/pages/app/cars/index.vue?macro=true";
import { default as cars0tsMp5d2qRMeta } from "/codebuild/output/src1965977027/src/transo/pages/app/cars.vue?macro=true";
import { default as driversEmtoAavj5KMeta } from "/codebuild/output/src1965977027/src/transo/pages/app/drivers.vue?macro=true";
import { default as employeesk15X9LDPnTMeta } from "/codebuild/output/src1965977027/src/transo/pages/app/employees.vue?macro=true";
import { default as indexpQ9ZLHvc9GMeta } from "/codebuild/output/src1965977027/src/transo/pages/app/index.vue?macro=true";
import { default as settingsYhX6hJVQ3UMeta } from "/codebuild/output/src1965977027/src/transo/pages/app/settings.vue?macro=true";
import { default as soloMCXmavxrODMeta } from "/codebuild/output/src1965977027/src/transo/pages/app/solo.vue?macro=true";
import { default as trailersiFS94EOJh6Meta } from "/codebuild/output/src1965977027/src/transo/pages/app/trailers.vue?macro=true";
import { default as appM9pNrHaZ7NMeta } from "/codebuild/output/src1965977027/src/transo/pages/app.vue?macro=true";
import { default as indexbalDrJw9IrMeta } from "/codebuild/output/src1965977027/src/transo/pages/index.vue?macro=true";
import { default as loginIs884wKBBFMeta } from "/codebuild/output/src1965977027/src/transo/pages/login.vue?macro=true";
import { default as registerhreKpXbOIbMeta } from "/codebuild/output/src1965977027/src/transo/pages/register.vue?macro=true";
export default [
  {
    path: appM9pNrHaZ7NMeta?.path ?? "/app",
    children: [
  {
    path: cars0tsMp5d2qRMeta?.path ?? "cars",
    children: [
  {
    name: addPkdR7cOMRoMeta?.name ?? "app-cars-add",
    path: addPkdR7cOMRoMeta?.path ?? "add",
    meta: addPkdR7cOMRoMeta || {},
    alias: addPkdR7cOMRoMeta?.alias || [],
    redirect: addPkdR7cOMRoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/cars/add.vue").then(m => m.default || m)
  },
  {
    name: _91id_93OXXofMzT1pMeta?.name ?? "app-cars-edit-id",
    path: _91id_93OXXofMzT1pMeta?.path ?? "edit/:id()",
    meta: _91id_93OXXofMzT1pMeta || {},
    alias: _91id_93OXXofMzT1pMeta?.alias || [],
    redirect: _91id_93OXXofMzT1pMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/cars/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexyfHSrOJ3vJMeta?.name ?? "app-cars",
    path: indexyfHSrOJ3vJMeta?.path ?? "",
    meta: indexyfHSrOJ3vJMeta || {},
    alias: indexyfHSrOJ3vJMeta?.alias || [],
    redirect: indexyfHSrOJ3vJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/cars/index.vue").then(m => m.default || m)
  }
],
    name: cars0tsMp5d2qRMeta?.name ?? undefined,
    meta: cars0tsMp5d2qRMeta || {},
    alias: cars0tsMp5d2qRMeta?.alias || [],
    redirect: cars0tsMp5d2qRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/cars.vue").then(m => m.default || m)
  },
  {
    name: driversEmtoAavj5KMeta?.name ?? "app-drivers",
    path: driversEmtoAavj5KMeta?.path ?? "drivers",
    meta: driversEmtoAavj5KMeta || {},
    alias: driversEmtoAavj5KMeta?.alias || [],
    redirect: driversEmtoAavj5KMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/drivers.vue").then(m => m.default || m)
  },
  {
    name: employeesk15X9LDPnTMeta?.name ?? "app-employees",
    path: employeesk15X9LDPnTMeta?.path ?? "employees",
    meta: employeesk15X9LDPnTMeta || {},
    alias: employeesk15X9LDPnTMeta?.alias || [],
    redirect: employeesk15X9LDPnTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/employees.vue").then(m => m.default || m)
  },
  {
    name: indexpQ9ZLHvc9GMeta?.name ?? "app",
    path: indexpQ9ZLHvc9GMeta?.path ?? "",
    meta: indexpQ9ZLHvc9GMeta || {},
    alias: indexpQ9ZLHvc9GMeta?.alias || [],
    redirect: indexpQ9ZLHvc9GMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: settingsYhX6hJVQ3UMeta?.name ?? "app-settings",
    path: settingsYhX6hJVQ3UMeta?.path ?? "settings",
    meta: settingsYhX6hJVQ3UMeta || {},
    alias: settingsYhX6hJVQ3UMeta?.alias || [],
    redirect: settingsYhX6hJVQ3UMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/settings.vue").then(m => m.default || m)
  },
  {
    name: soloMCXmavxrODMeta?.name ?? "app-solo",
    path: soloMCXmavxrODMeta?.path ?? "solo",
    meta: soloMCXmavxrODMeta || {},
    alias: soloMCXmavxrODMeta?.alias || [],
    redirect: soloMCXmavxrODMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/solo.vue").then(m => m.default || m)
  },
  {
    name: trailersiFS94EOJh6Meta?.name ?? "app-trailers",
    path: trailersiFS94EOJh6Meta?.path ?? "trailers",
    meta: trailersiFS94EOJh6Meta || {},
    alias: trailersiFS94EOJh6Meta?.alias || [],
    redirect: trailersiFS94EOJh6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/trailers.vue").then(m => m.default || m)
  }
],
    name: appM9pNrHaZ7NMeta?.name ?? undefined,
    meta: appM9pNrHaZ7NMeta || {},
    alias: appM9pNrHaZ7NMeta?.alias || [],
    redirect: appM9pNrHaZ7NMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app.vue").then(m => m.default || m)
  },
  {
    path: appM9pNrHaZ7NMeta?.path ?? "/pl/app",
    children: [
  {
    path: cars0tsMp5d2qRMeta?.path ?? "cars",
    children: [
  {
    name: addPkdR7cOMRoMeta?.name ?? "app-cars-add___pl",
    path: addPkdR7cOMRoMeta?.path ?? "add",
    meta: addPkdR7cOMRoMeta || {},
    alias: addPkdR7cOMRoMeta?.alias || [],
    redirect: addPkdR7cOMRoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/cars/add.vue").then(m => m.default || m)
  },
  {
    name: _91id_93OXXofMzT1pMeta?.name ?? "app-cars-edit-id___pl",
    path: _91id_93OXXofMzT1pMeta?.path ?? "edit/:id()",
    meta: _91id_93OXXofMzT1pMeta || {},
    alias: _91id_93OXXofMzT1pMeta?.alias || [],
    redirect: _91id_93OXXofMzT1pMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/cars/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexyfHSrOJ3vJMeta?.name ?? "app-cars___pl",
    path: indexyfHSrOJ3vJMeta?.path ?? "",
    meta: indexyfHSrOJ3vJMeta || {},
    alias: indexyfHSrOJ3vJMeta?.alias || [],
    redirect: indexyfHSrOJ3vJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/cars/index.vue").then(m => m.default || m)
  }
],
    name: cars0tsMp5d2qRMeta?.name ?? undefined,
    meta: cars0tsMp5d2qRMeta || {},
    alias: cars0tsMp5d2qRMeta?.alias || [],
    redirect: cars0tsMp5d2qRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/cars.vue").then(m => m.default || m)
  },
  {
    name: driversEmtoAavj5KMeta?.name ?? "app-drivers___pl",
    path: driversEmtoAavj5KMeta?.path ?? "drivers",
    meta: driversEmtoAavj5KMeta || {},
    alias: driversEmtoAavj5KMeta?.alias || [],
    redirect: driversEmtoAavj5KMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/drivers.vue").then(m => m.default || m)
  },
  {
    name: employeesk15X9LDPnTMeta?.name ?? "app-employees___pl",
    path: employeesk15X9LDPnTMeta?.path ?? "employees",
    meta: employeesk15X9LDPnTMeta || {},
    alias: employeesk15X9LDPnTMeta?.alias || [],
    redirect: employeesk15X9LDPnTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/employees.vue").then(m => m.default || m)
  },
  {
    name: indexpQ9ZLHvc9GMeta?.name ?? "app___pl",
    path: indexpQ9ZLHvc9GMeta?.path ?? "",
    meta: indexpQ9ZLHvc9GMeta || {},
    alias: indexpQ9ZLHvc9GMeta?.alias || [],
    redirect: indexpQ9ZLHvc9GMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: settingsYhX6hJVQ3UMeta?.name ?? "app-settings___pl",
    path: settingsYhX6hJVQ3UMeta?.path ?? "settings",
    meta: settingsYhX6hJVQ3UMeta || {},
    alias: settingsYhX6hJVQ3UMeta?.alias || [],
    redirect: settingsYhX6hJVQ3UMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/settings.vue").then(m => m.default || m)
  },
  {
    name: soloMCXmavxrODMeta?.name ?? "app-solo___pl",
    path: soloMCXmavxrODMeta?.path ?? "solo",
    meta: soloMCXmavxrODMeta || {},
    alias: soloMCXmavxrODMeta?.alias || [],
    redirect: soloMCXmavxrODMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/solo.vue").then(m => m.default || m)
  },
  {
    name: trailersiFS94EOJh6Meta?.name ?? "app-trailers___pl",
    path: trailersiFS94EOJh6Meta?.path ?? "trailers",
    meta: trailersiFS94EOJh6Meta || {},
    alias: trailersiFS94EOJh6Meta?.alias || [],
    redirect: trailersiFS94EOJh6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/trailers.vue").then(m => m.default || m)
  }
],
    name: appM9pNrHaZ7NMeta?.name ?? undefined,
    meta: appM9pNrHaZ7NMeta || {},
    alias: appM9pNrHaZ7NMeta?.alias || [],
    redirect: appM9pNrHaZ7NMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app.vue").then(m => m.default || m)
  },
  {
    path: appM9pNrHaZ7NMeta?.path ?? "/en/app",
    children: [
  {
    path: cars0tsMp5d2qRMeta?.path ?? "cars",
    children: [
  {
    name: addPkdR7cOMRoMeta?.name ?? "app-cars-add___en",
    path: addPkdR7cOMRoMeta?.path ?? "add",
    meta: addPkdR7cOMRoMeta || {},
    alias: addPkdR7cOMRoMeta?.alias || [],
    redirect: addPkdR7cOMRoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/cars/add.vue").then(m => m.default || m)
  },
  {
    name: _91id_93OXXofMzT1pMeta?.name ?? "app-cars-edit-id___en",
    path: _91id_93OXXofMzT1pMeta?.path ?? "edit/:id()",
    meta: _91id_93OXXofMzT1pMeta || {},
    alias: _91id_93OXXofMzT1pMeta?.alias || [],
    redirect: _91id_93OXXofMzT1pMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/cars/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexyfHSrOJ3vJMeta?.name ?? "app-cars___en",
    path: indexyfHSrOJ3vJMeta?.path ?? "",
    meta: indexyfHSrOJ3vJMeta || {},
    alias: indexyfHSrOJ3vJMeta?.alias || [],
    redirect: indexyfHSrOJ3vJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/cars/index.vue").then(m => m.default || m)
  }
],
    name: cars0tsMp5d2qRMeta?.name ?? undefined,
    meta: cars0tsMp5d2qRMeta || {},
    alias: cars0tsMp5d2qRMeta?.alias || [],
    redirect: cars0tsMp5d2qRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/cars.vue").then(m => m.default || m)
  },
  {
    name: driversEmtoAavj5KMeta?.name ?? "app-drivers___en",
    path: driversEmtoAavj5KMeta?.path ?? "drivers",
    meta: driversEmtoAavj5KMeta || {},
    alias: driversEmtoAavj5KMeta?.alias || [],
    redirect: driversEmtoAavj5KMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/drivers.vue").then(m => m.default || m)
  },
  {
    name: employeesk15X9LDPnTMeta?.name ?? "app-employees___en",
    path: employeesk15X9LDPnTMeta?.path ?? "employees",
    meta: employeesk15X9LDPnTMeta || {},
    alias: employeesk15X9LDPnTMeta?.alias || [],
    redirect: employeesk15X9LDPnTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/employees.vue").then(m => m.default || m)
  },
  {
    name: indexpQ9ZLHvc9GMeta?.name ?? "app___en",
    path: indexpQ9ZLHvc9GMeta?.path ?? "",
    meta: indexpQ9ZLHvc9GMeta || {},
    alias: indexpQ9ZLHvc9GMeta?.alias || [],
    redirect: indexpQ9ZLHvc9GMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: settingsYhX6hJVQ3UMeta?.name ?? "app-settings___en",
    path: settingsYhX6hJVQ3UMeta?.path ?? "settings",
    meta: settingsYhX6hJVQ3UMeta || {},
    alias: settingsYhX6hJVQ3UMeta?.alias || [],
    redirect: settingsYhX6hJVQ3UMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/settings.vue").then(m => m.default || m)
  },
  {
    name: soloMCXmavxrODMeta?.name ?? "app-solo___en",
    path: soloMCXmavxrODMeta?.path ?? "solo",
    meta: soloMCXmavxrODMeta || {},
    alias: soloMCXmavxrODMeta?.alias || [],
    redirect: soloMCXmavxrODMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/solo.vue").then(m => m.default || m)
  },
  {
    name: trailersiFS94EOJh6Meta?.name ?? "app-trailers___en",
    path: trailersiFS94EOJh6Meta?.path ?? "trailers",
    meta: trailersiFS94EOJh6Meta || {},
    alias: trailersiFS94EOJh6Meta?.alias || [],
    redirect: trailersiFS94EOJh6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app/trailers.vue").then(m => m.default || m)
  }
],
    name: appM9pNrHaZ7NMeta?.name ?? undefined,
    meta: appM9pNrHaZ7NMeta || {},
    alias: appM9pNrHaZ7NMeta?.alias || [],
    redirect: appM9pNrHaZ7NMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/app.vue").then(m => m.default || m)
  },
  {
    name: indexbalDrJw9IrMeta?.name ?? "index",
    path: indexbalDrJw9IrMeta?.path ?? "/",
    meta: indexbalDrJw9IrMeta || {},
    alias: indexbalDrJw9IrMeta?.alias || [],
    redirect: indexbalDrJw9IrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexbalDrJw9IrMeta?.name ?? "index___pl",
    path: indexbalDrJw9IrMeta?.path ?? "/pl",
    meta: indexbalDrJw9IrMeta || {},
    alias: indexbalDrJw9IrMeta?.alias || [],
    redirect: indexbalDrJw9IrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexbalDrJw9IrMeta?.name ?? "index___en",
    path: indexbalDrJw9IrMeta?.path ?? "/en",
    meta: indexbalDrJw9IrMeta || {},
    alias: indexbalDrJw9IrMeta?.alias || [],
    redirect: indexbalDrJw9IrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginIs884wKBBFMeta?.name ?? "login",
    path: loginIs884wKBBFMeta?.path ?? "/login",
    meta: loginIs884wKBBFMeta || {},
    alias: loginIs884wKBBFMeta?.alias || [],
    redirect: loginIs884wKBBFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginIs884wKBBFMeta?.name ?? "login___pl",
    path: loginIs884wKBBFMeta?.path ?? "/pl/login",
    meta: loginIs884wKBBFMeta || {},
    alias: loginIs884wKBBFMeta?.alias || [],
    redirect: loginIs884wKBBFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginIs884wKBBFMeta?.name ?? "login___en",
    path: loginIs884wKBBFMeta?.path ?? "/en/login",
    meta: loginIs884wKBBFMeta || {},
    alias: loginIs884wKBBFMeta?.alias || [],
    redirect: loginIs884wKBBFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: registerhreKpXbOIbMeta?.name ?? "register",
    path: registerhreKpXbOIbMeta?.path ?? "/register",
    meta: registerhreKpXbOIbMeta || {},
    alias: registerhreKpXbOIbMeta?.alias || [],
    redirect: registerhreKpXbOIbMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerhreKpXbOIbMeta?.name ?? "register___pl",
    path: registerhreKpXbOIbMeta?.path ?? "/pl/register",
    meta: registerhreKpXbOIbMeta || {},
    alias: registerhreKpXbOIbMeta?.alias || [],
    redirect: registerhreKpXbOIbMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerhreKpXbOIbMeta?.name ?? "register___en",
    path: registerhreKpXbOIbMeta?.path ?? "/en/register",
    meta: registerhreKpXbOIbMeta || {},
    alias: registerhreKpXbOIbMeta?.alias || [],
    redirect: registerhreKpXbOIbMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1965977027/src/transo/pages/register.vue").then(m => m.default || m)
  }
]