import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src1965977027/src/transo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src1965977027/src/transo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src1965977027/src/transo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_I4Hv4qpJHd from "/codebuild/output/src1965977027/src/transo/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_yVLowv6hDl from "/codebuild/output/src1965977027/src/transo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src1965977027/src/transo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src1965977027/src/transo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/codebuild/output/src1965977027/src/transo/.nuxt/formkitPlugin.mjs";
import composition_sLxaNGmlSL from "/codebuild/output/src1965977027/src/transo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/codebuild/output/src1965977027/src/transo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import pwa_client_Eorgdr12jA from "/codebuild/output/src1965977027/src/transo/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src1965977027/src/transo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src1965977027/src/transo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import _1_event_bus_Amc3cetZ0y from "/codebuild/output/src1965977027/src/transo/plugins/1-event-bus.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  supabase_client_I4Hv4qpJHd,
  payload_client_yVLowv6hDl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  formkitPlugin_pZqjah0RUG,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  pwa_client_Eorgdr12jA,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  _1_event_bus_Amc3cetZ0y
]